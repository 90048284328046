import { jtDispatch, queryOne } from "../../utils";
export default class JTCustomProgress {
  $root: Element;
  $steps: NodeList;
  $progress: Element;
  progressValue: number;
  progressMax: number;
  activeStepsThrough: number;

  constructor($root) {
    this.$root = queryOne($root);
    this.$steps = this.$root.querySelectorAll(
      '[data-jt-ref-array="JTCustomProgress.step"]'
    );
    this.$progress = this.$root.querySelector(
      '[data-jt-ref="JTCustomProgress.progress"]'
    );

    this.progressValue = Number((this.$progress as HTMLInputElement).value);
    this.progressMax = parseInt(this.$progress.getAttribute("max"));
    this.activeStepsThrough = this.calcActiveSteps();

    this.rerenderActiveSteps = this.rerenderActiveSteps.bind(this);
    this.calcActiveSteps = this.calcActiveSteps.bind(this);

    this.renderCurrentStep = this.renderCurrentStep.bind(this);
    this.rerenderActiveSteps();
  }

  setProgressValue(num: number) {
    this.progressValue = Number(num);
    this.activeStepsThrough = this.calcActiveSteps();
    this.rerenderActiveSteps();
  }

  calcActiveSteps(): number {
    return Math.round(
      (this.progressValue * this.$steps.length) / this.progressMax
    );
  }

  renderCurrentStep() {
    (this.$steps[this.activeStepsThrough - 1] as Element).classList.add(
      "current"
    );
  }

  rerenderActiveSteps() {
    this.$steps.forEach((stepEl, i) => {
      if (i < this.activeStepsThrough) {
        (stepEl as Element).classList.add("active");
        (stepEl as Element).classList.remove("current");
      } else {
        (stepEl as Element).classList.remove("active");
        (stepEl as Element).classList.remove("current");
      }
    });
    this.renderCurrentStep();
    jtDispatch.call(this, "change:jt:customprogress");
  }
}
