import { jtDispatch, queryOne, queryAll } from "../../utils";

interface IOffCanvasState {
  shown: boolean;
}

export default class JTOffcanvas {
  state: IOffCanvasState;
  $backdrop;
  $panel;
  $triggers;
  $toggle;

  constructor($panel, $triggerSelector) {
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    this.state = {
      shown: false,
    };

    try {
      this.$backdrop =
        queryOne('[data-jt-ref="JTOffcanvas.backdrop"]') ||
        this.createBackdrop();
      this.$panel = queryOne($panel);
      this.$triggers = $triggerSelector ? queryAll($triggerSelector) : null;

      this.$toggle = this.$panel.querySelector(
        '[data-jt-ref="JTOffcanvas.toggle"]'
      );
    } catch (err) {
      console.error(err);
    }

    this.init();
  }

  init() {
    this.$triggers?.forEach((element) => {
      element.addEventListener("click", this.show);
    });
    if (this.$toggle) {
      this.$toggle.addEventListener("click", this.hide);
    }
  }
  createBackdrop() {
    const div = document.createElement("div");
    div.setAttribute("aria-hidden", true.toString());
    div.classList.add("JTOffcanvas__backdrop");
    div.setAttribute("data-jt-ref", "JTOffcanvas.backdrop");

    document.body.appendChild(div);
    return div;
  }

  show() {
    this.$panel.setAttribute("aria-hidden", false);
    this.$panel.classList.add("show");
    this.$backdrop.classList.add("show");
    this.$backdrop.addEventListener("click", this.hide, {
      passive: true,
      once: true,
    });
    this.state.shown = true;
    jtDispatch.call("open:jt:offcanvas", this);
  }

  hide() {
    this.$panel.setAttribute("aria-hidden", true);
    this.$panel.classList.remove("show");
    this.$backdrop.classList.remove("show");
    this.state.shown = false;
    jtDispatch.call("close:jt:offcanvas", this);
  }
}
