import { jtDispatch, queryOne } from "./../../utils";
export default class JTPasswordField {
  $root;
  $input;
  $toggle;
  $icon;
  isPassword;
  toggleClass;

  constructor($root) {
    this.$root = queryOne($root);
    this.$input = this.$root.querySelector(
      '[data-jt-ref="JTPasswordField.input"]'
    );
    this.$toggle = this.$root.querySelector(
      '[data-jt-ref="JTPasswordField.toggle"]'
    );
    this.$icon = this.$root.querySelector(
      '[data-jt-ref="JTPasswordField.icon"]'
    );

    this.isPassword = true;

    this.init = this.init.bind(this);
    this.toggleClass = this.toggleInputType.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.init();
  }

  init() {
    this.$toggle.addEventListener("click", this.handleClick);
  }

  destroy() {
    this.$toggle.removeEventListener("click", this.handleClick);
  }

  toggleInputType() {
    this.$input.type = this.isPassword ? "text" : "password";
    this.isPassword
      ? this.$icon.classList.remove("fa-eye-slash")
      : this.$icon.classList.remove("fa-eye");
    this.isPassword
      ? this.$icon.classList.add("fa-eye")
      : this.$icon.classList.add("fa-eye-slash");
    this.isPassword = !this.isPassword;

    this.isPassword
      ? jtDispatch.call(this, "mask:jt:passwordfield")
      : jtDispatch.call(this, "unmask:jt:passwordfield");
  }

  handleClick() {
    this.toggleInputType();
  }
}
