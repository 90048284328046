import { queryOne, jtDispatch, queryAll } from "../../utils";

export default class JTClearInput {
  $btn: Element;
  $input: Element;

  constructor(selectorOrInput) {
    this.$btn = queryOne(selectorOrInput);
    this.$input = queryOne(this.$btn.getAttribute("data-jt-target"));
    this.addListener();
  }

  addListener = () => {
    this.$btn.addEventListener("click", this.clear);
  };

  clear = () => {
    try {
      (this.$input as HTMLInputElement).value = "";
      jtDispatch.call(this, "clear:jt:clearinput");
    } catch (err) {
      console.error("Error clearing input ", this);
    }
  };

  destroy = () => {
    this.$btn.removeEventListener("click", this.clear);
  };

  static initAll = () => {
    queryAll("[data-jt-ref='JTClearInput']").forEach(
      (el) => new JTClearInput(el)
    );
  };
}
