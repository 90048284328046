import "bootstrap-select/dist/css/bootstrap-select.min.css";
// import selectpicker from "bootstrap-select";

class SelectBox {
  bootstrap_select_instance;

  constructor(selector, options) {
    const defaultOptions = {
      style: "",
      styleBase: "form-control",
      dropupAuto: false,
      flip: false,
      static: true,
      display: "static",
      size: 6,
      tickIcon: "fal fa-times-circle",
    };

    options = {
      ...defaultOptions,
      ...options,
    };
    // @ts-ignore
    this.bootstrap_select_instance = $(selector).selectpicker(options);
  }

  refresh() {
    this.bootstrap_select_instance.selectpicker("refresh");
  }
}

function loadScript(selector, options) {
  // @ts-ignore
  return import("bootstrap-select/dist/css/bootstrap-select.min.css")
    .then(() => {
      // @ts-ignore
      return import("bootstrap-select")
        .then((module) => {
          // @ts-ignore
          window.$.selectpicker = module.default;
          // window.$.fn.selectpicker.Constructor.BootstrapVersion = '4';
        })
        .catch(console.error);
    })
    .catch(console.error);
}

function create(selector, options) {
  const defaultOptions = {
    style: "",
    styleBase: "form-control",
    dropupAuto: false,
    static: true,
    size: 6,
    tickIcon: "fal fa-times-circle",
  };

  options = {
    ...defaultOptions,
    ...options,
  };
  return new SelectBox(selector, options);
}

export default {
  SelectBox,
  create,
};
