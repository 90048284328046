export function jtDispatch(str: string): any {
  return window.dispatchEvent(
    new CustomEvent(str, { detail: { targetComponent: this } })
  );
}

export function queryOne(stringOrEl: string | Element): Element | null {
  try {
    if (typeof stringOrEl === "string") {
      return document.querySelector(stringOrEl);
    }
    if (stringOrEl instanceof Element) {
      return stringOrEl;
    }
    console.error("Incompatible argument for queryOne");
  } catch (err) {
    console.error(err);
  }
  return null;
}

export function queryAll(
  stringOrNodeList: string | Element[]
): Element[] | null {
  try {
    if (typeof stringOrNodeList === "string") {
      return Array.from(document.querySelectorAll(stringOrNodeList));
    }
    if (stringOrNodeList instanceof NodeList) {
      return stringOrNodeList;
    }
    console.error("Incompatible argument for queryAll");
  } catch (err) {
    console.error(err);
  }
  return null;
}
