import "./theme.scss";
import "./necessary-polyfills";

// @ts-ignore
import { version } from "./../../../package.json";

export * as default from "./components";
import * as JTComponents from "./components";

export const JTComponentsWindow = (function () {
  (<any>window).JTComponents = {
    version,
    ...JTComponents,
    JTSelectBox: JTComponents.SelectBoxFactory,
  };
})();
