export { default as JTClearInput } from "./JTClearInput";
export { default as JTRadioSelect } from "./JTRadioSelect";
export { default as JTPasswordField } from "./JTPasswordField";
export { default as JTCustomProgress } from "./JTCustomProgress";
export { default as JTOffcanvas } from "./JTOffcanvas";
export { default as SelectBoxFactory } from "./SelectBox";

function PhoneFactory(root) {
  return import("./JTPhone/JTPhone")
    .then((module) => {
      return new module.default(root);
    })
    .catch(console.error);
}

const JTPhone = {
  create: PhoneFactory,
};

export { JTPhone };
