import intlTelInput from "intl-tel-input";
// @ts-ignore
import utilsScript from "intl-tel-input/build/js/utils";
import "intl-tel-input/build/css/intlTelInput.min.css";

import { queryOne } from "../../utils";

function getIp(callback) {
  fetch("https://ipinfo.io/json?token=bc695182afc566", {
    headers: { Accept: "application/json" },
  })
    .then((resp) => resp.json())
    .catch(() => {
      return {
        country: "us",
      };
    })
    .then((resp) => callback(resp.country));
}

export default class JTPhone {
  $root;
  $input;
  $hiddenInput;
  $intl;

  constructor(root) {
    this.$root = queryOne(root);
    this.$input = this.$root.querySelector('[data-jt-ref="JTPhone.input"]');
    this.$hiddenInput = this.$root.querySelector(
      '[data-jt-ref="JTPhone.hidden"]'
    );
    this.$intl = intlTelInput(this.$input, {
      customContainer: "w-100 d-block",
      utilsScript: utilsScript,
      geoIpLookup: getIp,
      formatOnDisplay: true,
      nationalMode: false,
    });

    this.init();
  }

  init() {
    this.$input.addEventListener("input", this.handleInput);
  }

  handleInput = () => {
    this.format();
    this.$hiddenInput.value = this.$intl.getNumber();
  };

  format = () => {
    if (typeof intlTelInputUtils !== "undefined") {
      // utils are lazy loaded, so must check
      const currentText = this.$intl.getNumber(
        intlTelInputUtils.numberFormat.E164
      );
      if (typeof currentText === "string") {
        // sometimes the currentText is an object :)
        this.$intl.setNumber(currentText); // will autoformat because of formatOnDisplay=true
      }
    }
  };
}
